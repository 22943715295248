import React from "react"
import GlobalNavi from "../../components/GlobalNavi"
import { Link } from "gatsby"
import DocLayout from "../../components/DocLayout"
import { Box } from "@material-ui/core"
import BpHr from "../../components/BpHr"
import SEO from "../../components/seo"

export default function Home(arg) {
  return (
    <div>
      <SEO title={`このプロジェクトについて`} />
      <GlobalNavi />

      <div className="l-wrapper">
        <nav aria-label="パンくずリスト">
          <ul className={`breadcrumb`}>
            <li>
              <Link to={`/`}>Home</Link>
            </li>
            <li>
              <Link to={`/about`}>このプロジェクトについて</Link>
            </li>
          </ul>
        </nav>
      </div>

      <div className={`l-wrapper`}>
        <div className={`l-wrapper__inner`}>
          <DocLayout>
            <Box
              mt={{ xs: `30px`, sm: `60px` }}
              mb={{ xs: `20px`, sm: `40px` }}
            >
              <h2>ブックプロジェクト「そのうち届くラブレター」オンライン</h2>
            </Box>

            <p>
              『そのうち届くラブレター』は、ヨコハマ・パラトリエンナーレ2020の美術展です。新型コロナウイルス感染症が流行する2020年の状況を踏まえ、展覧会場で展示
              を行うのではなく、読む展覧会として、ブック(本)になりました。この特設サイトでは、本で紹介しきれない、映像作品や音声作品を掲載し、情報保障の新たな試みとして、音声と手話による鑑賞コンテンツを用意しました。
            </p>
            <p>
              参加作家は6人。そして、この6人の作品や芸術に対し、8人と1組の表現者たちがそれぞれの方法で応答を試みます。また今回、イギリスの作家、ジェス・トム
              の ドキュメンタリー映画『Me, My Mouth and
              I』をウェブサイトで特別上映します。
            </p>
            <p>
              <small>横浜市庁舎で一部作品展示も行います。 </small>
            </p>

            <Box
              mt={{ xs: `30px`, sm: `60px` }}
              mb={{ xs: `20px`, sm: `40px` }}
            >
              <h2 data-custom-type={`small`}>キュレーター</h2>
            </Box>
            <Box>
              <h3>金澤韻</h3>
              <p>
                1973年神奈川県生まれ。熊本市現代美術館など公立美術館での12年の勤務を経て、2013年に独立。これまで国内外での展覧会企画多数。トピックとして、グローバリゼーション、ニューメディアアート、そして日本の近現代史を扱い、時代・社会の変化とともに変容する人々の認識と、私たちに精神的な困難をもたらすものを捉え、問題解決の糸口を探る。近年の主な展覧会に、「AKI
                INOMATA: Significant Otherness
                生きものと私が出会うとき」（2019）「ウソから出た、まこと」（2019）「毛利悠子：ただし抵抗はあるものとする」（2018）（以上、十和田市現代美術館）「杭州繊維芸術三年展」（浙江美術館ほか、中国、2019）「こども時代」（パレ・ド・トーキョー、フランス、2018）など。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>田中みゆき</h3>
              <p>
                1980年大阪府生まれ。東京都渋谷公園通りギャラリー学芸員。アートセンターなどに勤務後、「障害は世界を捉え直す視点」をテーマにカテゴリーにとらわれないプロジェクトを企画する。価値や評価が定まる前の表現を扱うプロジェクトを通して、表現の見方や捉え方を当事者や鑑賞者とともに再考する。近年の企画に、『大いなる日常』展（2017年、NO-MA）、『音で観るダンスのワークインプログレス』（2017年～19年、KAAT神奈川芸術劇場）、映画『ナイトクルージング』（2019年公開）、『オーディオゲームセンター』（2017年～）など。東京大学DO-IT、Google
                Rareなど障害や多様性をテーマとしたカンファレンスにも登壇。2018年より東京工業大学リベラルアーツ研究教育院非常勤講師。早稲田大学文学学術院博士後期課程在籍。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>畑井 恵</h3>
              <p>
                1983年和歌山県生まれ。千葉市美術館学芸員。高校中退後、辻製菓専門学校、辻調グループフランス校シャトー・ド・レクレール卒業。パティシエとして2年間勤務の後、大阪大学文学部で西洋近現代美術史を専攻。大阪大学文学研究科博士前期課程修了、同研究科博士後期課程単位取得退学。丸亀市猪熊弦一郎現代美術館学芸員を経て2015年より現職。現代美術を中心とした展覧会企画及び教育普及事業を担当する。主な企画展に「目［mé］
                非常にはっきりとわからない」(2019年)、「小川信治－あなた以外の世界のすべて」(2016年)。公開制作やワークショップを通し、鑑賞者とともに空間をつくりかえていくプロジェクトシリーズ「つくりかけラボ」を企画担当。
              </p>
            </Box>

            <Box
              mt={{ xs: `30px`, sm: `60px` }}
              mb={{ xs: `20px`, sm: `40px` }}
            >
              <h2 data-custom-type={`small`}>
                ブックプロジェクトオンライン制作チームクレジット
              </h2>
            </Box>
            <Box mb={`100px`}>
              <p>
                アートディレクター : 米山菜津子 <br />
                映像ディレクター : 玉田伸太郎 <br />
                ウェブディレクター : 萩原俊矢 <br />
                ウェブコーダー : 和田響子
              </p>
              <p>
                手話通訳：瀬戸口裕子、和田みさ（美術と手話プロジェクト）
              </p>
            </Box>
          </DocLayout>
        </div>
      </div>
    </div>
  )
}
